import { render, staticRenderFns } from "./docView.vue?vue&type=template&id=0b7c3060&scoped=true&"
import script from "./docView.vue?vue&type=script&lang=ts&"
export * from "./docView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b7c3060",
  null
  
)

export default component.exports